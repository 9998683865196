
import './index.css';
import Chatbot from './App';
import reportWebVitals from './reportWebVitals';
import r2wc from '@r2wc/react-to-web-component';


// replace console.* for disable log on production

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    window.$isMobile = true
  
  } else {
    // false for not mobile device
    window.$isMobile = false
  }


const dsWidget = r2wc(Chatbot);


customElements.define("data-snack-widget", dsWidget);
 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
