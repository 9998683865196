
import React, { useState, useEffect } from "react";
// import axios from './Login/axiosConfig'
import './scss/fonts/embedFonts.css'


// test
import { Widget, addResponseMessage, toggleMsgLoader, toggleWidget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import './ds_styles.css'

if (process.env.NODE_ENV === 'development') {
  window.$ipRiskAPI = 'http://localhost:5000'

}

if (process.env.NODE_ENV === 'production') {
  window.$ipRiskAPI = 'https://datasnack.uc.r.appspot.com'
}




const Chatbot = () => {
  const [chatWindowOpen, setChatWindowOpen] = useState(false);
  const [allMessages, setAllMessages] = useState([])
  const [clientId, setClientId] = useState("")
  const [siloId, setSiloId] = useState("")
 

  useEffect(() => {
    // You can add a slight delay if needed to ensure the widget renders properly
    setTimeout(() => {
      if (window.$isMobile) {
        return
      }
      toggleWidget()
    }, 2500);
  }, []);


  const handleToggle = (open) => {
    setChatWindowOpen((prev) => !prev);
  };



  const handleNewUserMessage = (newMessage) => {
    const tempMessages = [...allMessages];
    tempMessages.push(
      { "sender": "DataSnack Bot", "message": newMessage }
    )
    toggleMsgLoader()
    fetch(`${window.$ipRiskAPI}/api/v1/domainBot`, {
      method: 'POST',
      body: JSON.stringify({
        contentSiloId: siloId,
        clientCompanyId: clientId,
        messages: tempMessages
      }),
    }).then((res) => res.json())
      .then((data) => {
        toggleMsgLoader()
        addResponseMessage(data.message);

        tempMessages.push(data)
        setAllMessages(tempMessages)
        console.log("result from ds bot", data)

      }).catch(error => {
        toggleMsgLoader()
        // console.log("error from bot", error)
        addResponseMessage("There was a problem processing your message. Please try again.");
      });
  }

  useEffect(() => {
    console.log("in use effect")

    let temp = document.getElementById("dataSnackChatBot")
    if (temp != null) {
      let clientId = temp.getAttribute("data-clientId")
      let siloId = temp.getAttribute("data-siloId")
      let introMessage = temp.getAttribute("data-introMessage")
      
      console.log("clientId", clientId)
      setClientId(clientId)

      console.log("siloId", siloId)
      setSiloId(siloId)

      console.log("introMessage", introMessage)
      addResponseMessage(introMessage);
    }


  }, [])


  return (
    <div>
      <Widget
        handleToggle={handleToggle}
        handleNewUserMessage={handleNewUserMessage}
        title="AI Chat w/ Facts"
        senderPlaceHolder="Message..."
        subtitle=""
      />
    </div>
  );
}

export default Chatbot;
